.input {
    height: 30px;
    width: 100%;
    border: none;
    font-size: 14px;
}
.input:focus {
    outline: none;
}

/* Estilo para mostrar toda la dirección en el desplegable de sugerencias de Autocomplete */
.pac-container {
    z-index: 10000;
    background-color: #fff;
    padding: 5px 5px 5px 5px;
    border-radius: 0 0 15px 15px;
    max-height: 320px;
    overflow-y: auto;
}

.pac-container > div:nth-child(1) {
    border-top: 0px;
    margin-top: 0px;
}

.pac-item {
    margin: 5px 5px;
    border-top: 1px solid #dee1e4;
}

.pac-item > span:nth-child(-n +6) {
    display: block;
    cursor: pointer;
    white-space: pre-line;
    line-height: 15px;
}

.pac-item > span:nth-child(-n +1) {
    display: none;
    opacity: 0;
}

.pac-item-query {
    margin-bottom: 5px;
    margin-top: 5px;
}
